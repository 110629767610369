<template>
  <Filter />
  <el-row :gutter="20">
    <el-col
      v-for="item in list"
      :key="item.name"
      :xs="24"
      :sm="12"
      :md="6"
      :lg="6"
      :xl="4"
    >
      <Card
        :name="item.name"
        nameLegendColor="#00CEBF"
        :mostUsedSoftwareCategoryName="item.mostUsedSoftwareCategoryName"
        :mostUsedSoftwareName="item.mostUsedSoftwareName"
        :averageRate="item.averageRate"
        averageRateDesc="当日有效工时占比"
        :averageHour="item.averageHour"
        averageHourDesc="总在线时间"
        :activeTime="item.activeTime"
      />
    </el-col>
  </el-row>
</template>

<script>
import Card from '../components/card';
import Filter from '../components/filter';
import * as mockData from '../mockData';

export default {
  components: {
    Card,
    Filter,
  },
  data() {
    return {
      list: mockData.usersData,
    }
  }
}
</script>